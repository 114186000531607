import React, { useState } from "react";
import {
  ThemeProvider,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Link,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import googlePng from "../assets/google.png";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { useAuth } from "../utils/useAuth"; // Import your useAuth hook
import LoadingSpinner from "../components/LoadingSpinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const theme = createTheme({
  palette: {
    primary: { main: "#2563eb" },
    secondary: { main: "#383938" },
  },
});

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth(); // Use your custom useAuth hook
  const auth = getAuth();

  const handleEmailLogin = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      await signInWithEmailAndPassword(auth, email, password);
      setLoading(false);
      toast.success("Giriş başarılı!");
      //   navigate("/dashboard");
    } catch (error) {
      console.error("Giriş hatası:", error);
      setLoading(false);
      toast.error("Giriş başarısız: " + error.message);
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      setLoading(true);
      await signInWithPopup(auth, provider);
      setLoading(false);
      toast.success("Google ile giriş başarılı!");
      navigate("/dashboard");
    } catch (error) {
      console.error("Google Girişi hatası:", error);
      setLoading(false);
      toast.error("Google ile giriş başarısız: " + error.message);
    }
  };

  // Redirect if user is already logged in
  if (user) {
    if (localStorage.getItem("deviceId")) {
      navigate("/results");
    } else {
      navigate("/dashboard");
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-md p-6 sm:p-8 w-full max-w-md">
          <h1 className="text-2xl font-semibold text-center text-blue-600 sm:text-3xl">
            Login here
          </h1>
          <p className="text-center text-gray-500 mt-2 text-sm sm:text-base">
            Enter your email and password to sign in
          </p>
          <form className="mt-6 space-y-4" onSubmit={handleEmailLogin}>
            <div>
              <input
                variant="outlined"
                margin="normal"
                className="w-full bg-[#F1F4FF] px-4 py-3 text-sm border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-base"
                required
                id="email"
                name="email"
                autoComplete="email"
                placeholder="Email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <input
                type="password"
                placeholder="Password"
                className="w-full bg-[#F1F4FF]  px-4 py-3 text-sm border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-base"
                variant="outlined"
                margin="normal"
                required
                name="password"
                id="password"
                autoComplete="new-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className="flex justify-end py-2">
              <Link href="/reset-password" variant="body2" color="primary">
                Forgot your password?
              </Link>
            </div>

            <button
              type="submit"
              variant="contained"
              color="secondary"
              className="w-full py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1 sm:py-3"
            >
              Sign In
            </button>
          </form>
          <p className="text-center text-gray-500 mt-2 text-sm sm:text-base">
            Or continue with
          </p>
          <div className="flex w-full justify-center space-x-4 mt-4">
            <button
              type="submit"
              variant="contained"
              color="secondary"
              className="w-full flex items-center justify-center gap-2 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1 sm:py-3"
              onClick={handleGoogleLogin}
            >
              <img
                src={googlePng}
                alt="Google"
                style={{ width: "24px", height: "24px" }}
              />
              Sign in with Google
            </button>
          </div>

          <div className="text-center text-gray-500 mt-4 text-sm sm:text-base">
            <Link href="/register" className="text-blue-600 hover:underline">
              Don't have an account? Sign up
            </Link>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
