// src/pages/Dashboard.js
import React, { useContext, useEffect, useState } from "react";

import { createTheme } from "@mui/material/styles";
import DeviceHub from "@mui/icons-material/DeviceHub";
import Logo from "../assets/logo.jpg"; // Your logo path here
import { useAuth } from "../utils/useAuth";
import LoadingSpinner from "../components/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { MyContext } from "../context/dataContextProvider";
import {
  ThemeProvider,
  Link,
  Box,
  Button,
  Typography,
  CssBaseline,
  Card,
  CardContent,
  TextField,
  InputAdornment,
} from "@mui/material";

const theme = createTheme({
  palette: {
    primary: { main: "#2563eb" },
    secondary: { main: "#383938" },
  },
});

export default function Dashboard() {
  const [deviceId, setDeviceId] = useState("");
  const [loading, setLoading] = useState(false); // Yükleme durumu
  const [showContent, setShowContent] = useState(true); // İçeriği gösterme durumu
  const navigate = useNavigate();
  const { loading: authLoading } = useAuth();
  const { handleConnectDevice, deviceFound, setDeviceFound } =
    useContext(MyContext);

  useEffect(() => {
    if (deviceFound) {
      navigate("/results");
    }
  }, [deviceFound, navigate]); // Add navigate to the dependency array

  if (authLoading) {
    return <LoadingSpinner loading={true} />; // Auth kontrolü sırasında spinner göster
  }

  const connectDevice = async (event) => {
    let refresh;
    event.preventDefault();
    setLoading(true);
    setShowContent(false);
    setDeviceFound(false);
    localStorage.setItem("deviceId", deviceId);
    await handleConnectDevice(deviceId, (refresh = true));
    setLoading(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-md p-6 sm:p-8 w-full max-w-md">
          <h1 className="text-2xl font-semibold text-center text-blue-600 sm:text-3xl">
            Connect Your Device
          </h1>
          <p className="text-center text-gray-500 mt-2 text-sm sm:text-base">
            Please enter your device ID to connect
          </p>
          <form className="mt-6 space-y-4" onSubmit={connectDevice}>
            <div>
              <input
                variant="outlined"
                margin="normal"
                className="w-full bg-[#F1F4FF] px-4 py-3 text-sm border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-base"
                required
                id="deviceId"
                name="deviceId"
                autoComplete="email"
                placeholder="Device Id"
                autoFocus
                value={deviceId}
                onChange={(e) => setDeviceId(e.target.value)}
              />
            </div>

            <button
              type="submit"
              variant="contained"
              color="secondary"
              className="w-full py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1 sm:py-3"
            >
              Connect
            </button>
          </form>
        </div>
      </div>
    </ThemeProvider>
  );
}
