// src/pages/ForgotPassword.js
import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Mail from "@mui/icons-material/Mail";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/useAuth"; // Import your useAuth hook
import LoadingSpinner from "../components/LoadingSpinner";
import { Link } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: { main: "#2563eb" },
    secondary: { main: "#383938" },
  },
});

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const auth = getAuth();
  const { user } = useAuth();

  const handleResetPassword = async (event) => {
    event.preventDefault();
    setMessage("");
    setError("");
    setLoading(true);

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Şifre sıfırlama linki e-postanıza gönderildi.");
      setTimeout(() => navigate("/login"), 5000); // Redirect after 5 seconds
    } catch (err) {
      setError("E-posta gönderilirken hata oluştu: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  // Redirect if user is already logged in
  if (user) {
    navigate("/dashboard"); // Redirect to dashboard if already logged in
    return null; // Prevent rendering the component
  }

  return (
    <ThemeProvider theme={theme}>
      {isLoading && <LoadingSpinner loading={true} />}
      <div className="flex items-center justify-center min-h-screen bg-gray-100 px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-md p-6 sm:p-8 w-full max-w-md">
          <h1 className="text-2xl font-semibold text-center text-blue-600 sm:text-3xl">
            Forgot Password?
          </h1>
          <p className="text-center text-gray-500 mt-2 text-sm sm:text-base">
            Enter your email to reset your password
          </p>
          <form className="mt-6 space-y-4" onSubmit={handleResetPassword}>
            <div>
              <input
                variant="outlined"
                margin="normal"
                className="w-full bg-[#F1F4FF] px-4 py-3 text-sm border rounded-md border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 sm:text-base"
                required
                id="email"
                name="email"
                autoComplete="email"
                placeholder="Email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <button
              type="submit"
              variant="contained"
              color="secondary"
              className="w-full py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1 sm:py-3"
            >
              Reset Password
            </button>
          </form>

          <div className="text-center text-gray-500 mt-4 text-sm sm:text-base">
            <Link href="/login" className="text-blue-600 hover:underline">
              Go back to Login
            </Link>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
